<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button> </p>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="add" v-right-code="'Systemnotice:Save'">新增</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="save" v-right-code="'Systemnotice:Save'">保存</el-button>
                </div>
            </div>
        </div>

        <div class="form-list">
            <el-form :model="dataSource" ref="_basicForm" :rules="baseRule">
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span> 公告标题：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="NoticeTitle">
                            <el-input v-model="dataSource.NoticeTitle" :maxlength="50"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span> 公告类型：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="NoticeType">
                            <DictionarySelect 
                                v-model="dataSource.NoticeType" 
                                datType='Int' 
                                dicTypeCode="NoticeTypeCode">
                            </DictionarySelect>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span> 有效期：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item>
                            <el-date-picker
                                v-model="startEndTime"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd HH:mm:ss">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span> 公告内容：
                    </el-col>
                    <el-col :span="17">
                        <el-form-item prop="NoticeContent">
                            <quill-editor
								v-model="dataSource.NoticeContent"
								:options="editorOption">
							</quill-editor>
                            <!-- <el-input type="textarea" :rows="15" v-model="dataSource.NoticeContent"></el-input> -->
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span> 发送范围：
                    </el-col>
                    <el-col :span="17">
                        <el-form-item prop="ReceiverNames" >
                            <el-input type="input" v-model="dataSource.ReceiverNames" @focus="orgUserControlShow" suffix-icon="el-icon-search" readonly></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
            </el-form>
        </div>

        <DialogEx :options="controlConfig.viewConfig" title="组织用户" @onOk="onOrgControlOk">
			<OrgUserControl ref="orgControl" :config="controlConfig.dataConfig" @onSelectedRow="onOrgControlSelectedRow">
			</OrgUserControl>
		</DialogEx>
    </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor';
const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
    
      [{'header': 1}, {'header': 2}],               // custom button values
      [{'list': 'ordered'}, {'list': 'bullet'}],
      //[{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
      [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
      [{'direction': 'rtl'}],                         // text direction
    
      [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
      [{'header': [1, 2, 3, 4, 5, 6, false]}],
    
      [{'color': []}, {'background': []}],          // dropdown with defaults from theme
      [{'font': []}],
      [{'align': []}],
      //['link', 'image', 'video'],
      ['clean']                                         // remove formatting button
    ];
export default {
    data() {
        return {
            startEndTime:[],
            editorOption: {                
                modules: {
                    toolbar: {
                        container: toolbarOptions,  // 工具栏
                    }
                }
            },
            baseRule: {
                NoticeTitle: [{
                    required: true,
                    message: '请输入公告标题',
                    trigger: 'blur'
                }],
                NoticeType: [{
                    required: true,
                    message: '请选择公告类型',
                    trigger: 'change'
                }],
                NoticeContent: [{
                    required: true,
                    message: '请输入公告内容',
                    trigger: 'blur'
                }],
                ReceiverNames: [{
                    required: true,
                    message: '请选择发送范围',
                    trigger: 'blur'
                }],
            },
            controlConfig: {
                viewConfig:{
                    visible: false,
                    size: 'normal',
                    customClass: 'controlDialog'
                },
                dataConfig:{
                    dataType:1,
                }
            },
        }
    },
    props: {
        config: {
            isDetailDisplay: false,
        },
        dataSource: {}
    },
    watch: {
        dataSource: {
            handler(curVal, oldVal) {
                if(curVal && curVal.Id!=oldVal.Id){
                    if(curVal.StartTime && curVal.EndTime){
                        this.startEndTime = [curVal.StartTime,curVal.EndTime];
                    }
                }
            },
            deep: true
        },
    },
    methods: {
        add(){
            this.Event.$emit(this.config.moduleEvent.add);
        },
        save(){
            var _this = this
            _this.$refs['_basicForm'].validate((valid) => {
                _this.isPassValidate = valid
                if (valid) {
                    debugger;
                    if(_this.startEndTime){
                        _this.dataSource.StartTime = _this.startEndTime[0];
                        _this.dataSource.EndTime = _this.startEndTime[1];
                    }
                    _this.$ajax.send(
                        'omsapi/systemnotice/save',
                        'post',
                        _this.dataSource,
                        (data) => {
                            _this.$parent.syncDataSource(data.Result);
                            _this.Event.$emit(this.config.moduleEvent.initial)
                            _this.Utils.messageBox('保存成功.', 'success')
                        }
                    )
                } else {
                    return false
                }
            })
        },
        orgUserControlShow(){
            this.controlConfig.viewConfig.visible=true;
        },
        onOrgControlOk(){
            var data = this.$refs.orgControl.getSelectedAll();
            var sourceIds = [];
            if(this.dataSource.ReceiverIds){
                sourceIds = this.dataSource.ReceiverIds.split(";")
            }
            var sourceNames = [];
            if(this.dataSource.ReceiverNames){
                sourceNames = this.dataSource.ReceiverNames.split(";")
            }
            var sourceList = [];
            sourceIds.forEach((item,index) => {
                sourceList.push({"UserId":item,"UserName":sourceNames[index]});
            });
            var targetList  = this.Utils.arrayUnion([sourceList,data.UserList],'UserId');
            if(targetList && targetList.length>0){
                var ids = this.Utils.selectionsToArr(targetList, "UserId");
                var names = this.Utils.selectionsToArr(targetList, "UserName");
                this.dataSource.ReceiverIds =  ids.join(";");
                this.dataSource.ReceiverNames =  names.join(";");
            }
        },
        onOrgControlSelectedRow(){
        }
    },
    components: {
        "OrgUserControl": resolve => {
            require(['../../../../components/orgusercontrol.vue'], resolve)
        },
        quillEditor
    }
}
</script>
<style>
    .controlDialog .h-full{
	  min-height: 300px;
   }
   .ql-container{ height: 400px !important;}
</style>